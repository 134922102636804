
	// Toggle Sidebar

	$('.toggle-sidebar-menu, .overlay').on('click', function() {
		
		$('html, .canvas, .overlay, .xs-sidebar').toggleClass('active');

		// Scroll the sidebar back to the top
		$('.xs-sidebar').scrollTop(0);
	
	});