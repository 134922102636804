
// clone the Primary Navigation and append items to sidebar

// get the Primary Nav links
var primaryNavLis = document.querySelectorAll('#xs-primary-nav .xs-primary-nav li'),

// document fragment to store the cloned links
tempPrimaryNav = document.createDocumentFragment(),

copyLi;

// Append each of the <li> to the sidebar "primary" navigation
for (i = 0, x = primaryNavLis.length; i < x; i++) {
	copyLi = primaryNavLis[i].cloneNode(true);
	tempPrimaryNav.appendChild(copyLi);
}

// Append document fragment to the sidebar primary nav <ul class="xs-side-primary-nav">
sidePrimaryNav.appendChild(tempPrimaryNav);