
    /* Some Functions
    //////////////////////////////////////// */

    // Create a generic DOM Element
    var createEl = function(el, elText, elClass) {
        var newEl = document.createElement(el);
        if (elText) {newEl.textContent = elText;}
        if (elClass) {newEl.className = elClass;}
        
        return newEl;
    };// /makeElem()


    // Copy Color style to parent element
    // referenced in Company Name and Tagline
    var copyColor = function(colorData, el) {
        var colorStart = colorData.indexOf('color=') + 7;
        var colorEnd = colorStart + 7;// returning a hex value
        var color = colorData.slice(colorStart, colorEnd);
        el.style.color = color;
    };// /copyColor()


    // Copy Font size to parent element
    // referenced in Company Name and Tagline
    var copyFontSize = function(fontData, el) {
        var sizeStart = fontData.indexOf('size=') + 6;
        var sizeEnd = sizeStart + 2;
        var fontSize = fontData.slice(sizeStart, sizeEnd);
        el.style.fontSize = fontSize + "px";
    };// /copyFontSize()


    // Copy Font Family/Face to parent element
    // referenced in Company Name and Tagline
    var copyFontFace = function(fontData, el) {
        var faceStart = fontData.indexOf('face=') + 6;
        var faceEnd = fontData.indexOf("'>");
        var fontFace = fontData.slice(faceStart, faceEnd);
        console.log(fontFace);
        el.style.fontFamily = fontFace;
    };// /copyFontFace()


    // get just the text out of some html
    // used here specifically for the <font> tags that xsites outputs from the wizard
    var stripText = function(html) {
       var tmp = document.createElement('div');
       tmp.innerHTML = html;
       return tmp.textContent || tmp.innerText || "";
    };

    // Smooth scrolling parallax for IE
    if(navigator.userAgent.match(/Trident\/7\./)) { // if IE
        $('body').on("mousewheel", function () {
            // remove default behavior
            event.preventDefault(); 

            //scroll without smoothing
            var wheelDelta = event.wheelDelta;
            var currentScrollPosition = window.pageYOffset;
            window.scrollTo(0, currentScrollPosition - wheelDelta);
        });
    }
