/**
 * Sticky footer bar for lead capture
 */


/**
 * Validation JS
 *
 * @author Cassie Koelsch
 * @options
 *     success: function(){},
 *     failure: function(){},
 *     emails: [],
 *     required: []
 */
$(function() {
    $.fn.validation = function(options) {

        var settings = $.extend({
            success: function() {},
            failure: function() {},
            emails: [],
            required: []
        }, options);

        return this.each(function() {

            var Methods = {
                /**
                 * Array containing all form errors
                 *
                 *
                 */

                errors: [],
                /**
                 * Check for valid email address in required field
                 *
                 * @prams
                 *      form: form being validated,
                 *      fields: each field that is validated (input, textarea, etc.)
                 */
                validateEmail: function(form, fields) {
                    fields.each(function() {
                        var emailRegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

                        // Here is where i do the magic tricks
                        if (!emailRegExp.test($(this).val())) {
                            var id = $(this).attr('id');
                            var label = $(form).find('label[for=' + id + ']');

                            $(this).addClass('error');
                            $(label).addClass('error');
                            $(this).tooltip('show');

                            Methods.errors.push({
                                label: $(label),
                                field: $(this)
                            });
                        } else {
                            $(this).tooltip('hide');
                        }
                    });
                },

                /**
                 * Check for input in required field
                 *
                 * @prams
                 *      form: form being validated,
                 *      fields: each field that is validated (input, textarea, etc.)
                 */
                validateRequired: function(form, fields) {
                    fields.each(function() {
                        if ($(this).is(':checkbox')) {
                            Methods.validateChecked(form, $(this));
                        }

                        if (!$(this).val()) {

                            var id = $(this).attr('id');
                            var label = $(form).find('label[for=' + id + ']');

                            $(this).addClass('error');
                            $(label).addClass('error');
                            $(this).tooltip('show');

                            Methods.errors.push({
                                label: $(label),
                                field: $(this)
                            });
                        } else if (!$(this).hasClass('error')) {
                            $(this).tooltip('hide');
                        }
                    });
                },
                /**
                 * Check for checked checkbox if checkbox is required
                 *
                 * @prams
                 *      form: form being validated,
                 *      field: the checkbox field that is being validated
                 */
                validateChecked: function(form, field) {
                    if (!field.prop('checked')) {

                        var id = field.attr('id');
                        var label = $(form).find('label[for=' + id + ']');

                        field.addClass('error');
                        $(label).addClass('error');
                        $(this).tooltip('show');

                        Methods.errors.push({
                            label: $(label),
                            field: field
                        });
                    } else {
                        $(this).tooltip('hide');
                    }
                },
                /**
                 * Reset errors on each submit
                 *
                 * @prams
                 *      form: form being validated
                 */
                reset: function(form) {
                    this.errors = Methods.errors = [];
                    form.find('.error').removeClass('error');
                },
                /**
                 * Return true if form has no errors
                 *
                 *
                 */
                isValid: function() {
                    return (Methods.errors.length == 0)
                }
            }

            $(this).attr('novalidate', true).on('submit', function(e) {
                var emailFields = $('[required][type=email]').add(settings.emails);
                var requiredFields = $('[required]').add(settings.required);

                Methods.reset($(this));
                Methods.validateEmail($(this), $(this).find(emailFields));
                Methods.validateRequired($(this), $(this).find(requiredFields));

                //If form is valid, call success function or if form is not valid call failure function
                Methods.isValid() && settings.success({
                    form: $(this),
                    event: e
                }) || !Methods.isValid() && settings.failure({
                    form: $(this),
                    event: e,
                    errors: Methods.errors
                })
            })
        });
    }
}(jQuery));

function getFilename() {
                var pathArray = window.location.pathname.split('/');
                return pathArray[1];
            }


            //Call the validation function and send input to AJAX Call
            $('#lead-form').validation({
                failure: function(data) {
                    data.event.preventDefault();
                },
                success: function(data) {
                    data.event.preventDefault();
                    var filename = getFilename() === ""?"Home":getFilename();
                    console.log(filename);
                    //AJAX Call Here 
                    $.ajax({
                        url: "/admin/wizard/ajax/request.aspx?application=CaptureLead",
                        method: "POST",
                        cache: false,
                        data: {"pageName": filename, "name": $('#inputName').val(), "email": $('#inputEmail').val(), "question": $('#inputQuestion').val() },
                        success: function(msg) {
                            //On success hide the form and show a thank you message
                            $('#lead-form').hide();
                            $('.sticky-footer').css("height", "55px");
                            $('.submit-message').fadeIn('slow');
                            setTimeout(function() {
                                $('.sticky-footer').fadeOut();
                            }, 5000);
                            setCookie("completedFooter", true, 1);
                        },
                        error: function(msg) {
                            alert("There was an error submitting your message. Please try again later.");
                            console.log(JSON.stringify(msg));
                        }
                    });

        
                }

            });


//Expands the sticky footer on mobile devices 
$(".expand").toggle(function() {
    if ($(window).width() < 768) {
        $('.sticky-footer').animate({
            height: '260px',
        });
    }
}, function() {
    $('[data-toggle="tooltip"]').tooltip('hide');
    if ($(window).width() < 768) {
        $('.sticky-footer').animate({
            height: '55px',
        });
    }
});

$(window).resize(function() {
    $('[data-toggle="tooltip"]').tooltip("hide");
    $('.sticky-footer').css("height", "55px");
});


//Set Cookie after submit
function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}


//Check for cookie and hide
function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

if (getCookie("completedFooter")) {
    //hide sticky footer 
    $('.sticky-footer').css("display", "none");
}




