
	$(window).on('resize load', function() {

		// Check window width has actually changed and it's not just iOS triggering a resize event on scroll
        if ( $(window).width() !== windowWidth ) {

            // Update the window width for next time
            windowWidth = $(window).width();

            $('html, .overlay, .canvas, .xs-sidebar').removeClass('active');

            // Scroll the sidebar back to the top
            $('.xs-sidebar').scrollTop(0);

        }

        // <table> Add Horizontally scrolling div container to tables
        $('.xs-page-content td table').each(function() {

            var $tableWidth = $(this).width(),

                $hasTableScroll = $(this).parent('div').hasClass('tableScroll');

                // Get the width of the parent element
                $parentWidth = $(this).parent().width();

            // IF there isn't already a <div> wrapping the table AND the table is wider than the <article>
            if ( !$hasTableScroll && $tableWidth > $parentWidth ) {
                $(this).wrap('<div class="tableScroll"></div>');

            // OTHERWISE Remove the wrapping <div>
            } else if ( $hasTableScroll && $tableWidth <= $parentWidth ) {
                $(this).unwrap();
            }
        });// /tableScroll()

	});// /window.on resize load()

}); // /document.ready