
    // Create navigation listitems
    var createListItem = function ( anchor ) {

        'use strict';
		if (!String.prototype.includes) {
			String.prototype.includes = function (str) {
				var returnValue = false;

				if (this.indexOf(str) !== -1) {
					returnValue = true;
				}
				return returnValue;
			}
		}
        var page = anchor.PageIndex;

        // create a <li>
        var listItem = document.createElement('li');
        listItem.setAttribute('data-page', page);

        // if there's a link add the link
        if ( anchor.PageURL ) {
            var theLink = createEl('a', anchor.DisplayPageName);
            theLink.setAttribute('href', anchor.PageURL.includes('http:') || anchor.PageURL.includes('https:') ? anchor.PageURL : '/' + anchor.PageURL);
            listItem.appendChild(theLink);  
        }

        // if indented add 'indent' class
        if ( anchor.Indent === true ) {
            if (listItem.classList) {
                listItem.classList.add('indent');
            } else {
                listItem.className += ' ' + 'indent';
            }
        }
        
        // add Sub Nav <ul class="sub-nav"> if menu HasChildren
        if ( anchor.HasChildren !== 0 ) {
            var elName = anchor.FileName.replace(/.x/g, '');
            var subNav = document.createElement('ul');
            subNav.setAttribute('id', 'sub-nav-' + elName);
            subNav.className = 'sub-nav';

            // add 'has-children' class to <li>
            if (listItem.classList) {
                listItem.classList.add('has-children');
            } else {
                listItem.className += ' ' + 'has-children';
            }

            // append the sub-nav <ul> to the <li>
            listItem.appendChild(subNav);
        }

        // Connect the nested navigation to the parent
        if ( anchor.Location == "Child" ) {
            var parentName = anchor.Parent.replace(/.x/g, '');
            listItem.setAttribute('data-parent', parentName);
        }

        return listItem;

    };// /createListItem()


    // Add primary navigation listitems to the ul
    var createPrimaryNav = function () {
        for ( i = 0, x = XSITES.collection.length; i < x; i++ ) {
            primaryNav.appendChild( XSITES.collection[i] );
        }
    };// /createPrimaryNav()


    // Add secondary navigation listitems to the ul
    var createSecondaryNav = function () {        
        for ( i = 0, x = XSITES.collection.length; i < x; i++ ) {
            secondaryNav.appendChild( XSITES.collection[i] );
        }
        
        // show secondary nav IF it has links
        if ( XSITES.collection.length > 0 ) {
            if (secondaryNav.classList) {
                secondaryNav.classList.add('show');
                toggleNav.classList.add('show');
            } else {
                secondaryNav.className += ' ' + 'show';
                toggleNav.className += ' ' + 'show';
            }
        }
    };// /createSecondaryNav()


    // Add alt navigation listitems to the ul
    var createAltNav = function () {
        
        for ( var i in XSITES.collection ) {
            if (XSITES.collection.hasOwnProperty(i)) {
                altNav.appendChild( XSITES.collection[i] );
            }
        }

        // show secondary nav IF it has links
        if ( XSITES.collection.length > 0 ) {
            if (altNav.classList) {
                altNav.classList.add('show');
                toggleNav.classList.add('show');
            } else {
                altNav.className += ' ' + 'show';
                toggleNav.className += ' ' + 'show';
            }
        }
    };// /createAltNav()


    // Add nested/child navigation listitems to the ul under the correct parent
    var createChildNav = function () {
        for ( i = 0, x = XSITES.collection.length; i < x; i++ ) {
            var listElem = document.getElementById('sub-nav-' + XSITES.collection[i].getAttribute('data-parent'));
            listElem && listElem.appendChild(XSITES.collection[i]);
        }
    };// /createChildNav()


    // All organized content data
    XSITES.organizedContent = {
        primary: [],
        secondary: [],
        alt: [],
        child: []
    };


    // Add navigation data to arrays based on location
    for (i = 0, x = strPageData.length; i < x; i++ ) {
        var locale = strPageData[i].Location.toLowerCase();
        XSITES.organizedContent[locale].push( strPageData[i] );
    }

    
    var buildNav = function ( target, location ) {
        
        XSITES.collection = [];
        var anchor, page;

        // Creates array of navigation objects with data
        if (location == 'child') {
        
            for (i = 0, x = target.length; i < x; i++) {

                anchor = target[i];
                page = anchor.PageIndex;

                // Add objects to array with data
                XSITES.collection[i] = anchor;
            } 
        
        } else {
        
            for (i = 0, x = target.length; i < x; i++) {

                anchor = target[i];
                page = anchor.PageIndex;
                
                // Add objects to array with data by page number order
                XSITES.collection[page] = anchor;
            }       
        
        } 

        if (location == 'child') {
            // Make list items of child navigation elements without spacers and with parent page data attached
            for (i = 0, x = XSITES.collection.length; i < x; i++) {
                if ( XSITES.collection[i] !== undefined ) {
                    XSITES.collection[i] = createListItem(XSITES.collection[i]);
                } 
            }
        } else if (location == 'alt') {
            // Make list items of alt navigation elements without spacers
            for (i = 0, x = XSITES.collection.length; i < x; i++) {
                if ( XSITES.collection[i] !== undefined ) {
                    XSITES.collection[i] = createListItem(XSITES.collection[i]);
                } 
            }

            XSITES.collection.shift();
        } else {
            // Make list items of primary and secondary navigation elements with spacers
            for (i = 1; i < XSITES.collection.length; i++) {
                if ( XSITES.collection[i] === undefined ) {
                    var spacer = document.createElement('li');
                    spacer.className = 'spacer';

                    XSITES.collection[i] = spacer;
                } else {
                    XSITES.collection[i] = createListItem(XSITES.collection[i]);
                }
            }

            XSITES.collection.shift();
        }  

        // append the list item to the appropriate <ul>
        if ( location == 'primary' && primaryNav ) {
            createPrimaryNav();
        } else if ( location == 'secondary'  && secondaryNav ){
            createSecondaryNav();
        } else if ( location == 'child' ) {
            createChildNav();
        } else if ( location == 'alt' && altNav ) {
            createAltNav();
        }

    }; // /buildNav()


    // Create arrays of data sorted by location
    buildNav( XSITES.organizedContent.primary, 'primary' );
    buildNav( XSITES.organizedContent.secondary, 'secondary' );
    buildNav( XSITES.organizedContent.alt, 'alt' );
    buildNav( XSITES.organizedContent.child, 'child' );

