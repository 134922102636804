$(document).ready(function() {

	/* Variables
	//////////////////////////////////////// */

	var

	// Select some DOM Elements
	logo = document.querySelector('.xs-company-logo'),
	companyName = document.querySelector('.xs-company-name'),
	tagLine = document.querySelector('.xs-company-tagline'),
	portraitImg = document.querySelector('.xs-profile-picture'),

	primaryNav = document.querySelector('#xs-primary-nav .xs-primary-nav'),
	sidePrimaryNav = document.querySelector('.xs-side-primary-nav'),
	secondaryNav = document.querySelector('.xs-secondary-nav'),
	altNav = document.querySelector('.xs-alt-nav'),

	footerPhoneLink = document.querySelector('.xs-footer-phone'),
	footerEmailLink = document.querySelector('.xs-footer-email'),

	toggleNav = document.querySelector('.toggle-sidebar-menu'),


	windowWidth = $(window).width(),

	x, i,

	// namespace object for navigation arrays and page content
	XSITES = XSITES || {};