
// add page content from strAccountData
var renderPageContent = (function() {

    'use strict';
    
    XSITES.pageContent = strAccountData[0];

    // Get the Company Name Text with stripText() in functions.js
    var companyNameText = decodeURIComponent(stripText(XSITES.pageContent.CompanyName));

    // Add company Name
    if ( XSITES.pageContent.DisplayCompName === 1 && companyName ) {
        
        if (companyName.classList) {
            companyName.classList.add('xs-visible');
        } else {
            companyName.className += ' ' + 'xs-visible';
        }

        // Company Name Color
        copyColor(XSITES.pageContent.CompanyName, companyName);

        // Company name font size
        copyFontSize(XSITES.pageContent.CompanyName, companyName);

        // Company name font face/family
        copyFontFace(XSITES.pageContent.CompanyName, companyName);

        // if <b></b> Bold
        if ( XSITES.pageContent.CompanyName.indexOf('</b>') > 0 ) {
            companyName.style.fontWeight = "bold";
        }

        // if <i></i> Italic
        if ( XSITES.pageContent.CompanyName.indexOf('</i>') > 0 ) {
            companyName.style.fontStyle = "italic";
        }

        // if <u></u> Underline
        if ( XSITES.pageContent.CompanyName.indexOf('</u>') > 0 ) {
            companyName.style.textDecoration = "underline";
        }

        // companyName.innerHTML = decodeURIComponent(XSITES.pageContent.CompanyName).replace(/size=/g, 'data-size=').replace(/color=/g, 'data-color=');

        companyName.innerHTML = companyNameText;
    }

    
    // Company Motto/Tagline
    if (XSITES.pageContent.CompanyTagline !== '' && tagLine ) {

        if (tagLine.classList) {
            tagLine.classList.add('show');
        } else {
            tagLine.className += ' ' + 'show';
        }
        
        // Motto/Tagline Color
        copyColor(XSITES.pageContent.CompanyTagline, tagLine);

        // Company Motto/Tagline size
        copyFontSize(XSITES.pageContent.CompanyTagline, tagLine);

        // add Tagline
        tagLine.innerHTML = decodeURIComponent(XSITES.pageContent.CompanyTagline).replace(/size=/g, 'data-size=').replace(/color=/g, 'data-color=');
    }

    // Get Company Logo URL
    var companyLogoUrl = XSITES.pageContent.CompanyLogoURL;

    // Company Logo
    if ( XSITES.pageContent.DisplayLogo === 1 && logo && (/\.(gif|jp?g|tiff|png)$/i).test(XSITES.pageContent.CompanyLogoURL)) {
        logo.setAttribute('alt', companyNameText + " Logo");
        logo.setAttribute('src', companyLogoUrl);
    } else if ( XSITES.pageContent.DisplayLogo === 0 && logo || !(/\.(gif|jp?g|tiff|png)$/i).test(XSITES.pageContent.CompanyLogoURL)) {
        logo.style.display = 'none';
    }

    // Sidebar Photo/Portrait
    if ( XSITES.pageContent.DisplayPortrait === 1 && portraitImg ) {
        portraitImg.setAttribute('alt', companyNameText + " Portrait Photo");
    } else if ( XSITES.pageContent.DisplayPortrait === 0 && portraitImg ) {
        portraitImg.style.display = 'none';
    }


    // Footer Office Phone Button
    if ( XSITES.pageContent.DisplayOfficePhone === true && XSITES.pageContent.Phone !== '' && footerPhoneLink ) {
        var phoneText = document.createTextNode(XSITES.pageContent.Phone);
        footerPhoneLink.appendChild(phoneText);
        footerPhoneLink.setAttribute('href', 'tel:+1' + XSITES.pageContent.Phone);
    } else if ( XSITES.pageContent.DisplayOfficePhone === false && footerPhoneLink ) {
        footerPhoneLink.style.display = 'none';
    }

    // Footer Email Button
    if ( XSITES.pageContent.DisplayPrimaryEmail === true && XSITES.pageContent.Email !== '' && footerEmailLink ) {
        var emailText = document.createTextNode(XSITES.pageContent.Email);
        footerEmailLink.appendChild(emailText);
        footerEmailLink.setAttribute('href', 'mailto:' + XSITES.pageContent.Email);
    } else if ( XSITES.pageContent.DisplayPrimaryEmail === false && footerEmailLink ) {
        footerEmailLink.style.display = 'none';
    }
    
}());// /renderPageItems() **immediately invoked**