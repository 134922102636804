// Add wmode="transparent" to Flash Movies/YouTubes so's the sub-nav fly-outs can be viewed over them
    
    // https://helpx.adobe.com/flash/kb/flash-object-embed-tag-attributes.html#main_Browser_support_for_Window_Mode__wmode__values
    $(function () {
        $('object').each(function () {
            var wrap, clone;
            
            wrap = $('<div>').addClass('embed-wrapper');
            
            clone = $(this).clone();
            clone.append(
                $('<param>').attr({
                    name: 'wmode',
                    value: 'transparent'
                })
            ).find('embed').attr('wmode', 'transparent');
    
            $(this).before( wrap.append(clone) );
            $(this).remove();
        });

        $('iframe[src*="youtube"]').each(function () {
            var src = $(this).attr('src');

            src += (src.match(/\?/g) ? '&' : '?' ) + "wmode=transparent";
            
            $(this).attr({
                src: src,
                wmode: "Opaque"
            });
        });
    });